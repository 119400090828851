<template>
  <div id="TheHeader">
    <nav class="navbar navbar-dark bg-color-primary-2 navbar-expand-lg">
      <div class="container-fluid">
        <router-link class="navbar-brand" :to="{name : 'Home'}">
          <img
            src="../assets/logo.png"
            alt="My App Kiani"
            title="My App Kiani"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Home'}">صفحه اصلی</router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{name : 'Profile'}"
                v-if="this.$store.state.auth.isAuthentication"
                >پروفایل
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Article'}">مقاله ها</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Search'}">جستجو پیشرفته</router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{name : 'AddArticle'}"
                v-if="this.$store.state.auth.isAuthentication"
                >نوشتن مقاله
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'CryptoCurrency'}">ارز دیجیتال</router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="#"
                @click="this.$store.state.auth.toggleCalculator = true"
                >ماشین حساب
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{name : 'Register'}"
                v-if="!this.$store.state.auth.isAuthentication"
                >ثبت نام
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{name : 'Login'}"
                v-if="!this.$store.state.auth.isAuthentication"
                >ورود
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="#"
                @click="doLogout"
                v-if="this.$store.state.auth.isAuthentication"
              >
                خروج
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  methods: {
    doLogout(e) {
      e.preventDefault();
      this.$store.commit("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
a.navbar-brand {
  color: black !important;
}

.navbar-brand img {
  height: 50px;
}
</style>
